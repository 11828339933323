.mainDiv {
    background-color: #2b302c;
    margin: 0px;
    height: 100vh;
}

.titleDiv {
    background-color: #2b302c;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.navbarColour {
    background-color: #2b302c;
}
.title {
    color: #f5e9d4;
    font-family: 'Merriweather', Times, serif;
    font-style: italic;
    font-weight: 700;
    font-size: 2.6rem;
}

.subtitle {
    color: #f5e9d4;
    font-family: 'Merriweather', Times, serif;
    font-weight: 400;
    font-size: 20px;
}

.subtext {
    color: wheat;
    opacity: 0.6;
    font-family: 'Merriweather', Times, serif;
    font-style: italic;
    font-size: 16px;
}
