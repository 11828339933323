body {
}

.status {
    border-radius: 6px;
    text-align: center;
    padding: 2px 0px;
    font-weight: bold;
    font-size: 0.9em;
    width: 100%;
}

.nextAction {
    border-radius: 4px;
    border-left: 6px solid;
    padding: 4px;
    color: #0040ff;
    border-left-color: #0040ff;
    background-color: #0040ff20;
}

.gridButton {
    font-size: 1em !important;
    text-transform: none !important;
}

.dropZone {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    width: 100%;
    height: 100px;
    margin-top: 4px;
}

.hintHighlight {
    background-color: #ffffa0;
}
