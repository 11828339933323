body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    /*font-size: 12px;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageHeader {
    font-size: 18px;
    font-weight: 500;
    margin: 8px 0;
}

.dialogHeader {
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0;
}

.gridButton {
    font-size: 1em !important;
    text-transform: none !important;
}

.tabButton {
    text-transform: none !important;
    font-size: 14px !important;
    text-align: left !important;
    padding: 12px 24px 12px 0 !important;
}
.tabButtonMaxWidth {
    text-transform: none !important;
    font-size: 14px !important;
    text-align: left !important;
    padding: 12px 16px 12px 0 !important;
    min-width: 100% !important;
    align-items: start !important;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
}

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    *::-webkit-scrollbar-track {
        background: #f8f8f8;
        border-radius: 20px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 20px;
        border: 3px solid #f8f8f8;
    }

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
